import {
  AnterAja,
  JNE,
  JNT,
  LionParcel,
  Ninja,
  PosIndonesia,
  SiCepat,
  Wahana
} from "@/public/images";
import {
  BagSvg,
  CartSolidSvg,
  CategorySolidSvg,
  CostumerService,
  Fb3DSvg,
  FbSvg,
  FooterDelivery,
  FooterPersentage,
  GroupSolidSvg,
  Ig3DSvg,
  IgSvg,
  LoginSolidSvg,
  LogoutSvg,
  MapPaperSolidSvg,
  NotifSolidSvg,
  PaperSvg,
  PeopleSolidSvg,
  ShieldOutlineSvg,
  Tiktok3DSvg,
  TiktokSvg,
  TransactionSvg,
  Twitter3DSvg,
  TwitterSvg,
  UnlockSvg,
  Wa3DSvg,
  WalletSolidSvg,
  WaSvg
} from "@/public/svg";
import { signOut } from "next-auth/react";

export function listMenuProfileHeader({ longged = false }) {
  const defaultList = [
    {
      uri: "/",
      title: "Kategori",
      icon: (props) => <CategorySolidSvg {...props} />
    },
    {
      uri: "/cart",
      title: "Cart",
      icon: (props) => <CartSolidSvg {...props} />
    }
    // {
    //   uri: "/",
    //   title: "Notifikasi",
    //   icon: (props) => <NotifSolidSvg {...props} />,
    // },
  ];

  const otherMenu = longged
    ? [
        {
          uri: "/member-area/dashboard",
          title: "Dashboard",
          icon: (props) => <CategorySolidSvg {...props} />,
          divider: true
        },
        {
          uri: "/member-area/list-order",
          title: "List Order",
          icon: (props) => <BagSvg {...props} />
        },
        {
          uri: "/member-area/dompet-digital",
          title: "Dompet Digital",
          icon: (props) => <WalletSolidSvg {...props} />
        },
        {
          uri: "/member-area/customer-saya",
          title: "Customer Saya",
          icon: (props) => <MapPaperSolidSvg {...props} />,
          divider: true
        },
        {
          uri: "/member-area/laporan-belanja",
          title: "Laporan Belanja",
          icon: (props) => <PaperSvg {...props} />
        },
        // {
        //   uri: "/member-area/laporan-tagihan",
        //   title: "laporan Tagihan",
        //   divider: true,
        //   icon: (props) => <TransactionSvg {...props} />,
        // },
        {
          uri: "/member-area/jaringan-saya",
          title: "Jaringan Saya",
          icon: (props) => <GroupSolidSvg {...props} />
        },
        {
          uri: "/member-area/profile-saya",
          title: "Profile Saya",
          icon: (props) => <PeopleSolidSvg {...props} />
        },
        {
          uri: "/",
          title: "Logout",
          color: "error",
          icon: (props) => <LogoutSvg {...props} />
        }
      ]
    : [
        {
          uri: "/signin",
          title: "Masuk",
          color: "primary",
          icon: (props) => <LoginSolidSvg className={props?.className} />
        }
      ];

  defaultList?.push(...otherMenu);
  return defaultList;
}

export function footerBenefitList({ other = false }) {
  const benefitList = [
    {
      icon: (props) => <ShieldOutlineSvg {...props} />,
      title: "Dijamin Original",
      desc: `Produk yang dijual di ${process.env.COMPANY_NAME} 100% Original`
    },
    {
      icon: (props) => <FooterDelivery {...props} />,
      title: "Pengiriman Terpercaya",
      desc: "Jasa pengiriman dengan keamanan yang terpercaya"
    },
    {
      icon: (props) => <CostumerService {...props} />,
      title: "Bantuan Pelanggan",
      desc: "Jasa pengiriman dengan keamanan yang terpercaya"
    },
    {
      icon: (props) => <FooterPersentage {...props} />,
      title: "Keuntungan Belanja",
      desc: "Jasa pengiriman dengan keamanan yang terpercaya"
    }
  ];

  const listOther = [
    {
      email: "Hello@gmail.com",
      title: `Berlangganan di ${process.env.COMPANY_NAME}`,
      desc: "Daftarkan email Anda untuk mendapatkan update promosi dan penawaran menarik dari kami"
    },
    {
      title: process.env.COMPANY_NAME,
      list: [
        {
          title: "Tentang Kami",
          url: "/"
        },
        {
          title: "Hubungi Kami",
          url: "/"
        },
        {
          title: "Syarat, Ketentuan, dan Privasi",
          url: "/terms"
        },
        {
          title: "FAQ",
          url: "/"
        }
      ]
    },
    {
      title: "Layanan",
      list: [
        // {
        //   title: "Cara Pemesanan",
        //   url: "/",
        // },
        // {
        //   title: "Cara Pembayaran",
        //   url: "/",
        // },
        {
          title: "Informasi Pengiriman",
          url: "/member-area/list-order"
        },
        {
          title: "Cek Stok",
          url: "/cek-stok"
        },
        {
          title: "Cek Status Transaksi",
          url: "/member-area/list-order"
        },
        {
          title: "Pembatalan Pesanan",
          url: "/member-area/list-order"
        }
      ]
    },
    {
      title: "Jasa Pengiriman",
      images: [
        SiCepat,
        LionParcel,
        JNT,
        JNE,
        Ninja,
        Wahana,
        PosIndonesia,
        AnterAja
      ]
    }
  ];

  return other ? listOther : benefitList;
}

export function listSosmed({ icon_3d = false, whatsapp = false }) {
  const Icon = {
    wa: (props) => (icon_3d ? <Wa3DSvg {...props} /> : <WaSvg {...props} />),
    fb: (props) => (icon_3d ? <Fb3DSvg {...props} /> : <FbSvg {...props} />),
    ig: (props) => (icon_3d ? <Ig3DSvg {...props} /> : <IgSvg {...props} />),
    twitter: (props) =>
      icon_3d ? <Twitter3DSvg {...props} /> : <TwitterSvg {...props} />,
    tiktok: (props) =>
      icon_3d ? <Tiktok3DSvg {...props} /> : <TiktokSvg {...props} />
  };

  let list = [
    {
      icon: (props) => <Icon.wa {...props} />,
      url: "/",
      value: "wa"
    },
    {
      icon: (props) => <Icon.fb {...props} />,
      url: "https://www.facebook.com/OfficialNbrs",
      value: "fb"
    },
    {
      icon: (props) => <Icon.ig {...props} />,
      url: "https://www.instagram.com/nbrsofficial",
      value: "ig"
    },
    {
      icon: (props) => <Icon.twitter {...props} />,
      url: "/",
      value: "twitter"
    },
    {
      icon: (props) => <Icon.tiktok {...props} />,
      url: "https://www.tiktok.com/@nbrsofficial",
      value: "tiktok"
    }
  ];

  !whatsapp && list.shift();

  return list;
}

export function listActionCek() {
  return [
    {
      title: "Cek Stok Produk",
      url: "/cek-stok"
    },
    {
      title: "Cek Status Order",
      url: "/member-area/list-order"
    }
  ];
}

export function jaminanTransaksi() {
  return `Jaminan transaksi 100% aman & mudah. Harga terbaik langsung dari ${process.env.COMPANY_NAME}. Customer service cepat dan tanggap.`;
}

export function listSideBar() {
  return [
    {
      label: "Dashboard",
      href: "dashboard",
      icon: (props) => <CategorySolidSvg className={props?.className} />
    },
    {
      label: "Dompet Digital",
      href: "dompet-digital",
      icon: (props) => <WalletSolidSvg className={props?.className} />
    },
    {
      label: "List Order",
      href: "list-order",
      icon: (props) => <CartSolidSvg className={props?.className} />
    },
    {
      label: "Laporan Belanja",
      href: "laporan-belanja",
      icon: (props) => <TransactionSvg className={props?.className} />
    },
    // {
    //   label: "Laporan Tagihan",
    //   href: "laporan-tagihan",
    //   icon: (props) => <PaperSvg className={props?.className} />,
    // },
    {
      label: "Jaringan Saya",
      href: "jaringan-saya",
      anotherHref: [
        "jaringan-saya/tambah-jaringan",
        "jaringan-saya/edit-jaringan"
      ],
      icon: (props) => <GroupSolidSvg className={props?.className} />
    },
    {
      label: "Customer Saya",
      href: "customer-saya",
      anotherHref: [
        "customer-saya/tambah-customer",
        "customer-saya/edit-customer"
      ],
      icon: (props) => <MapPaperSolidSvg className={props?.className} />,
      divider: true
    },
    {
      label: "Profile Saya",
      href: "profile-saya",
      icon: (props) => <PeopleSolidSvg className={props?.className} />
    },
    {
      label: "Logout",
      onClick: () => {
        signOut();
      },
      icon: (props) => <LogoutSvg className={props?.className} />,
      colors: "error"
    }
  ];
}
